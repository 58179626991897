import React from "react";
import { Container, Button, Text, Image } from "@atoms";
import PeopleTopPattern from "@svg/PeopleTopPattern";

const CtaColored = ({ heading, copy, button, image }) => {
  return (
    <div className="my-10 sm:my-20">
      <Container smallerPadding>
        <div className="relative flex grid-cols-2 flex-col gap-12 overflow-hidden rounded-3xl bg-purple px-10 py-10 text-white sm:grid sm:px-16">
          <div className="relative z-10 self-center">
            {/* heading */}
            <Text variant="h3" className="mb-4">
              {heading}
            </Text>

            {/* copy */}
            <Text variant="body" className="my-6 font-medium">
              {copy}
            </Text>

            {/* button */}
            {button?.url && (
              <Button to={button?.url} size="md">
                {button?.text}
              </Button>
            )}
          </div>

          {/* image */}
          <div>
            {image && (
              <Image
                image={image}
                className="rounded-2xl"
                aspectRatio={[4, 3]}
              />
            )}
          </div>

          <PeopleTopPattern className="absolute -left-20 -top-16 z-0 w-80 text-teal md:-top-24 md:left-0 md:w-[28rem]" />
        </div>
      </Container>
    </div>
  );
};

export default CtaColored;
